<template>
  <section id="contact">


    <h1 class="section-header">BIZE ULAŞIN</h1>

    <div class="contact-wrapper">

      <!-- Left contact page -->



      <!-- Left contact page -->

      <div class="direct-contact-container">

        <div class="contact-list">

          <div class="list-item">
            <i class="fa fa-map-marker fa-2x"></i>
              <div class="contact-text place">23 RUE DE BOURGOGNE  75007 PARIS
              </div>
          </div>

            <div class="list-item">
              <i class="fa fa-phone fa-2x"></i>
                <div class="contact-text phone">
                  <a href="tel:1-212-555-5555" title="Give me a call">0699692293</a>
                </div>
            </div>

          <div class="list-item"><i class="fa fa-envelope fa-2x"></i>
            <div class="contact-text gmail"><a href="mailto:pkazanci@hotmail.com" title="Send me an email">pkazanci@hotmail.com</a>

            </div></div>






        </div>

        <hr>
        <ul class="social-media-list">
          <li><a href="#" target="_blank" class="contact-icon">
            <i class="fa fa-github" aria-hidden="true"></i></a>
          </li>
          <li><a href="#" target="_blank" class="contact-icon">
            <i class="fa fa-codepen" aria-hidden="true"></i></a>
          </li>
          <li><a href="#" target="_blank" class="contact-icon">
            <i class="fa fa-twitter" aria-hidden="true"></i></a>
          </li>
          <li><a href="#" target="_blank" class="contact-icon">
            <i class="fa fa-instagram" aria-hidden="true"></i></a>
          </li>
        </ul>
        <hr>

        <div class="copyright">&copy; 2022 par PAYKAZAN. </div>

      </div>

    </div>

  </section>

</template>

<script>

export default {
name: "Contact"
}
</script>

<style scoped>
.contact-list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


}

.list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #aaa;
  width: 100%;
  padding-top: 20px;
  margin-left: 90px;
}

.direct-contact-container{
  width: 100%;

}

#contact {
  width: 100%;
  height: 100%;
  background-color: #1c1c1d;
}


.place{
  padding-left: 11px;
}
.phone{
  padding-left: 6px;
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  font: 300 60px 'Oswald', sans-serif;
  color: #FFFFFF;
  text-transform: uppercase;

}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  max-width: 840px;
}

/* Left contact page */

.section-header{
  font-size: 30px;
}

textarea {
  max-width: 400px;
  background-color: #FFFFFF;
  color: #fff;
  letter-spacing: 1px;
}





/* Begin Right Contact Page */
.direct-contact-container {
  max-width: 400px;
}

/* Location, Phone, Email Section */




.contact-text {
  font: 300 15px 'Lato', sans-serif;
margin-left: 10px;
  color: #bbb;
}
.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}


/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: #2c2c2e;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.social-media-list li:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all .2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #fff;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37,0.74,0.15,1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px 'Oswald', sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255,255,255,.6);
}

/* Begin Media Queries*/
@media screen and (max-width: 850px) {

  .list-item {
    margin-left: 0;

  }


  #contact {
    width: 100%;
    height: 100%;

  }
  .section-header{
    padding-bottom: 0;
    font-size: 30px;
  }
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .direct-contact-container, .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 0;
    max-width: 300px;
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}



</style>
