<template>

<div class="imgg">
<div class="ctn-txt">
<div class="text-img">
  <p>PAYKAZAN CONSULTING</p>
  <div/>
</div>
  <div class="txtt">
    <p>TÜRKİYE FRANSA/FRANSA TÜRKİYE PROJELERİNİZ İÇİN DOĞRU ADRES</p>
  </div>
  </div>
<div class="ctn-btn">
  <a id="scroll-down-animation" href='#About'>
  <span class="mouse">

    <span class="move">

    </span>

  </span>
    <h2>Scroll down</h2>
  </a>
</div>
</div>
</template>

<script>
import Nav from "@/components/Nav.vue";
export default {
  name: "Head",
  components: {
    Nav
  }

}
</script>

<style scoped>
.ctn-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.ctn-txt{
  margin-top: 40vh;
}

#scroll-down-animation {
  margin-left: 35px;
  text-decoration:none;
}

h2 {
  color: #fff;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: 200;
  font-size: 16px;
}

.mouse {
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  border: 2px solid #fff;
  height: 100px;
  width: 50px;
  position: relative;
}

.move {
  position: absolute;
  background-color: #fff;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: move 2s linear infinite;
}

@keyframes move {
  0% {
    transform: translate(-50%,10px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%,40px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%,80px);
    opacity: 0;
  }
}




.imgg{
  background-image: url(../assets/psrr.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content:  space-between;
  align-items: center;

}
.text-img{
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  text-shadow: 2px 2px 4px #000000;
  margin-left: 105px;


}
.txtt{
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  text-shadow: 2px 2px 4px #000000;
  margin-left: 45px;
}

@media screen and (max-width: 968px){
  .imgg{
    background-image:url(../assets/wall.jpg) ;

  }
}

@media screen and (max-width: 768px) {
  .imgg {
    margin-left: 0;
    background: no-repeat center/100% url("../assets/wall.jpg");



  }



  .txtt{
    font-size: 20px;
    margin-left: 0;
  }
  .text-img{
    font-size: 30px;
    margin-left: 0;
  }
  #scroll-down-animation{
   align-self: center;
    margin: 0;
    padding: 0;
  }
}







</style>
