<template>
  <div class="app">

<Nav/>

<Headd  id="Home"/>


<Header id="About"/>

    <Service id="Service"/>
    <Choix id="Choix"/>
    <div id="Contact">
    <Contact />
    </div>

  </div>


</template>

<script>

import Nav from "@/components/Nav.vue";
import Header from "@/components/Header.vue";
import Service from "@/components/Service";
import Headd from "@/components/Headd.vue";
import Contact from "@/components/Contact.vue";
import Choix from "@/components/Choix.vue";


export default {
  name: 'Homepage',
  components: {
    Nav,Header,Service,Headd,Contact,Choix
  }

}

</script>

