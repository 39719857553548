<template>
  <div class="nav">
    <input type="checkbox" id="nav-check">
    <div class="nav-header">
      <div class="nav-title">
       PAYKAZAN CONSULTING
      </div>
    </div>
    <div class="nav-btn">
      <label for="nav-check">
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>

    <div class="nav-links">

      <a href="#Home">ANA SAYFA</a>
      <a href="#About">BİZ KİMİZ</a>
      <a href="#Service" >HİZMETLERİMİZ</a>
      <a href="#Choix" >SEÇENEK</a>
      <a href="#Contact">BİZE ULAŞIN</a>
    </div>
  </div>




</template>

<script>



export default {
  name: "Nav"
}



</script>

<style scoped>

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: 'segoe ui',serif;
}

.nav {
  z-index: 1000;
  overflow: hidden;
position: fixed;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  background-color: #2c2c2e;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.nav > .nav-header {
 width: 18%;
}

.nav > .nav-header > .nav-title {

  font-size: 22px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {

  font-size: 18px;
}
.nav-links{

  width: 70%;
display: flex;
  justify-content: center;

}
.nav > .nav-links > a {
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #efefef;

}

.nav > .nav-links > a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.nav > #nav-check {
  display: none;
}

@media (max-width:820px) {
  .nav > .nav-header {
    width: 90%;
  }
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nav > .nav-links {

    position: fixed;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(30vh - 50px);
    overflow-y: auto;
  }
}



</style>
