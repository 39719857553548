<template>
  <div class="ctn-service"  >

    <div class="titre-service" >
      HİZMETLERİMİZ
    </div>

    <div class="ctn-card">


      <div class="card">
        <img class="card-img-top" src="../assets/femme.webp" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">DİNLİYORUZ</h5>
          <p class="card-text">Projenizi Dinliyoruz</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../assets/reu.webp" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">ANALİZ EDİYORUZ</h5>
          <p class="card-text">Hukuksal, Finansal, Ekspertiz</p>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top" src="../assets/reuni.webp" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-titlee">PROJENİZİ GERÇEKLEŞTİRİYORUZ</h5>
          <p class="card-text">A'dan Z'ye Anahtar Teslimi Yapıyoruz.</p>
        </div>
      </div>


    </div>

<!--ligneeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee-->
    <div class="ctn-blog">
      <div class="blog-card alt">
        <div class="meta">
          <img src="../assets/entreprise.jpeg" alt="paris" class="photo">

        </div>
        <div class="description">
          <h1>ŞİRKET KURULUŞU</h1>

          <div class="list-des">
            <ul>
              <li>
                Şirket sözleşmesinin hazırlanması
              </li>
              <li>Banka Hesabı Açılması</li>
              <li>Ticaret Sicil Kaydının Yapılması</li>
              <li>Sermaye Deblokajı</li>
              <li>Mali Müşavir Bulunması</li>
            </ul>
          </div>


        </div>
      </div>

      <div class="blog-card alt">
        <div class="meta">
          <img src="../assets/parisfr.jpg" alt="paris" class="photo">

        </div>

        <div class="description">
          <h1>FRANSA'DA GAYRIMENKUL ALIMI</h1>
<div class=list-des>
          <ul>
            <li>
              Bölgeye ve evin şekline karar veriyoruz
            </li>
            <li>Bütçe belirliyoruz</li>
            <li>Bulduğumuz evleri beraber geziyoruz</li>
            <li>Ön Anlaşmayı İmzalıyoruz</li>
            <li>Kredi Dosyanızı hazırlıyoruz</li>
            <li>Banka Hesabınızı Açıyoruz</li>
            <li>Sigortanızı Yapıyoruz</li>
            <li>Noterde İmzalara eşlik ediyoruz</li>
            <li>Anahtarınızı teslim ediyoruz.</li>
          </ul>
</div>
        </div>
      </div>


    </div>
    <div class="ctn-blog2">
      <div class="blog-card">
        <div class="meta">
          <img src="../assets/deco.jpg" alt="paris" class="photo">


        </div>
        <div class="description">
          <h1>İÇ DEKORASYON</h1>
<div class="list-des">
          <ul>
            <li>
              İç Mimarımız ile zevkinize göre planlama yapıyoruz
            </li>
            <li>Tamirat ve dekorasyon konusunda tecrübeli ekibimizle çalışmaları yapıyoruz.</li>
            <li>Mobilya alınması ve dekorasyon konusunda yardımcı oluyoruz.</li>
          </ul>
</div>
        </div>
      </div>


      <div class="blog-card">
        <div class="meta">
          <img src="../assets/Paris.webp" alt="paris" class="photo">

        </div>
        <div class="description">
          <h1>FRANSA'YA YERLEŞME</h1>

          <p> Uzman avukat ağımızla durumunuza uygun analizi yapıp, dosya hazırlanması, başvuru, sigorta gibi süreçleri takip edip, yanınızda oluyoruz.</p>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "Service"
}
</script>

<style scoped>

.ctn-service{
  background-color:#f7f9fb;
}

/*PEN STYLES*/

.photo{
  width: 100%;
  height: 100%;
}

.titre-service{
  font-size: 50px;
  font-weight: 700;
  color: #000;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 150px;
}

/*************************************** blogCard ***************************************/
.list-des{
  margin-top: 20px;
  font-size: 15px;

}
.ctn-blog{
  display: flex;

  justify-content: center;
  align-items: center;

  /* flex-flow: row wrap; */
  flex-direction: column;
  flex-wrap: wrap;


width: 100%;
  padding: 15px;
  gap: 5px;
}
.ctn-blog2{
  display: flex;
align-items: center;


  /* flex-flow: row wrap; */
  flex-direction: column;
  flex-wrap: wrap;


  width: 100%;
  padding: 15px;
  gap: 5px;
}


.blog-card {
  display: flex;
  flex-direction: row;


  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  box-shadow: 1px 0px 40px -5px #797979 ;
}
.blog-card a {
  color: inherit;
}

.blog-card:hover .photo {
  transform: scale(1.3) rotate(3deg);
}
.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
}
.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}
.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}
.blog-card .details a {
  -webkit-text-decoration: dotted underline;
  text-decoration: dotted underline;
}
.blog-card .details ul li {
  display: inline-block;
}
.blog-card .details .author:before {
  font-family: FontAwesome,serif;
  margin-right: 10px;
  content: "";
}
.blog-card .details .date:before {
  font-family: FontAwesome,serif;
  margin-right: 10px;
  content: "";
}
.blog-card .details .tags ul:before {
  font-family: FontAwesome;
  content: "";
  margin-right: 10px;
}
.blog-card .details .tags li {
  margin-right: 2px;
}
.blog-card .details .tags li:first-child {
  margin-left: -4px;
}
.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}
.blog-card .description h1,
.blog-card .description h2 {
  font-family: Poppins, sans-serif;
}
.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}
.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}
.blog-card .description .read-more {
  text-align: right;
}

.blog-card .description .read-more a:after {
  content: "";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}
.blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
}
.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}
.blog-card p:first-of-type {
  margin-top: 1.25rem;
}

.blog-card:hover .details {
  left: 0%;
}
@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    max-width: 700px;
  }
  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }
  .blog-card .description {
    flex-basis: 60%;
  }
  .blog-card .description:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .blog-card.alt {
    flex-direction: row-reverse;
  }
  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }
  .blog-card.alt .details {
    padding-left: 25px;
  }
}
@media (max-width: 640px) {
  .blog-card {
    flex-direction: column;
  }
  .titre-service{
    font-size: 30px;
  }
}


/***********************************Card 1***********************************/
.ctn-card{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  padding-bottom: 200px;

}
.card{
  width: 20%;

}

.card{
  transition: box-shadow .3s;

  border-radius:5px;
  border: 2px solid #ccc;
  background: #fff;


}
.card:hover {
  box-shadow: 0 0 30px rgb(0, 0, 0);
}

.card-title{
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.card-titlee{
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;


}
.card-text{
  font-size: 15px;
  font-weight: 400;
  color: #000;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 50px;}



@media (max-width: 1200px) {
  .ctn-card {
    flex-direction: column;

  }
  .card{
    width: 70%;
    margin-bottom: 50px;

  }
}

</style>
