<template>

<div class="ctn-all">

  <div class="flex-titre">
    HAKKIMDA
  </div>

  <div class="flex-container">

    <div class="item1">

      <div class="text-head">
        BANKA, FİNANS, GAYRİMENKUL

        Payel Kazancı, İstanbul'da doğdu yüksek öğrenimini Fransa'da tamamladı.


        20 yıl boyunca Türkiye'nin en büyük özel Bankası olan T.İş Bankası A.Ş'nin iştiraki olan İŞBANK AG Paris Şubesi ve Frankfurt Genel Müdürlüğünde görev yaptı.


        1998-2018 tarihleri arasında bireysel hesaplar, firma hesapları, bireysel krediler, firma kredileri, gayrimenkul kredileri, varlık yönetimi konusunda yüzlerce dosya ile ilgilendi.


        Sektörde edindiği tecrübe, bilgi ve insan birikimi ile Türkiye'den Fransa'ya, Fransa'dan Türkiye'ye yapılacak yatırımlar konusunda danışmanlık yapmaktadır.


        Banka, avukatlık bürosu, mali müşavir ve noterlere işbirliği içerisinde olup bireysel ve kurumsal ihtiyaçlarınız konusunda  danışmanlık  yapmak temel amacımızdır.

      </div>

    </div>
    <div class="card">
      <div class="image">
        <img src="../assets/PAyel.png"/>
      </div>
      <div class="details">
        <div class="center">
        <Contact/>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>

import Contact from "@/components/Contact-head.vue";

export default {
  components: {
    Contact,
  }
}
</script>

<style scoped>
.flex-titre{
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bold;
  color: #000000;
  margin-top: 50px;
  margin-bottom: 90px;



}
.flex-container {

  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  margin-bottom: 25vh;

  width: 100%;
  gap: 5px;


}

.flex-container > div{
  border-radius: 2px;
  padding: 1px;
}
.item1{
  width: 50%;
  margin-right: 80px;
  align-self: center;

}
.titre-head{
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  padding-bottom: 15px;
}
.text-head {
  font-size: 20px;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
}


/*******************************************MEDIA QUERY********************************************/

@media screen and (max-width: 1200px) {
  .flex-container {

    padding: 15px;

  }
  .item1{
    width: 50%;
    margin: 0;
    align-self:center;


  }

}


@media screen and  (max-width: 999px)


{
  .item1{

    align-self:center;
    margin: 10px;

  }
  .text-head{
    font-size: 15px;
    color: #000000;
    text-align: justify;
    text-justify: inter-word;
    width: 100%;
  }

  .flex-titre{
    margin-bottom: 0;

  }
  @media screen and (max-width: 820px){
    .text-head{
      font-size: 25px;

    }

    .item1{
      width: 100%;

    }

  }


}
@media screen and (max-width: 720px) {





  .flex-container {
    padding: 15px;

  }
  .item1{
    width: 100%;

  }
.text-head{
  width: 100%;
  font-size: 15px;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
}
.flex-titre{
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin-top: 50px;
  margin-bottom: 10px;
}
}
.card {

  background: #000;
}
.card .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.card .image img {
  width: 100%;
  transition: .5s;
}
.card:hover .image img {
  opacity: .5;
  transform: translateX(30%);/*100%*/
}
.card .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;/*100%*/
  height: 100%;
  background: #1c1c1d;
  transition: .5s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}
.card:hover .details {
  transform: perspective(2000px) rotateY(0deg);
}
.card .details .center {

  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.card .details .center h1 {
  margin: 0;
  padding: 0;
  color: #ff3636;
  line-height: 20px;
  font-size: 20px;
  text-transform: uppercase;
}
.card .details .center h1 span {
  font-size: 14px;
  color: #262626;
}
.card .details .center p {
  margin: 10px 0;
  padding: 0;
  color: #262626;
}
.card .details .center ul {
  margin: 10px auto 0;
  padding: 0;
  display: table;
}
.card .details .center ul li {
  list-style: none;
  margin: 0 5px;
  float: left;
}
.card .details .center ul li a {
  display: block;
  background: #262626;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transform: 0.5s;
}
.card .details .center ul li a:hover {
  background: #ff3636;
}




</style>
